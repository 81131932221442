import React, {Component} from 'react'
import styled from 'styled-components'
import {Form, Alert} from 'react-bootstrap';
import CTAButton from '../utils/CTAButton'
import { checkPasswordToken, updateShopper, isLoggedIn, isBrowser } from "../User/User"

const StyledForm = styled(Form)`
  font-size: 1.25rem;
`

export default class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: '',
      nonce: '',
      token: '',
      shopperID: '',
      alert: null
    }
  }
    
  handleSubmit = event => {
    event.preventDefault();
    
    let updateShopperData = {};
    
    if (this.state.password) {
      if (this.state.password == this.state.confirmPassword) {
        updateShopperData.password = this.state.password;
      } else {
        this.setState({'alert': {
          type: "danger",
          message: "Error: Passwords do not match."
        }})
      }
    }
    
    if (updateShopperData && this.state.token && this.state.shopperID) {
      updateShopperData.shopperid = this.state.shopperID;
      updateShopper(this.state.token, updateShopperData).then( (response) => {
        if (response.result == "OK") {
          this.setState({'alert': {
            type: "success",
            message: 'Your password has been updated.',
            link: "/coupons#signin",
            linkText: "Click here to sign in."
          }})
        } else {
          this.setState({'alert': {
            type: "danger",
            message: "Error: " + response.message
          }})
        }
      });
    } else {
      this.setState({'alert': {
        type: "danger",
        message: "Error: Reset key not found."
      }})
    }
  }
  
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  
  clearForm = () => {
    this.setState({
      password: '',
      confirmPassword: '',
      nonce: '',
      token: '',
      shopperId: '',
      alert: null
    });
  }

  componentDidMount(){
    if (isBrowser()) {
      function getUrlParameter(name) {
          name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
          var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
          var results = regex.exec(location.search);
          return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      };
      
      let nonce = getUrlParameter('key');
      
      if (nonce) {
        this.setState({'nonce': nonce});
        
        checkPasswordToken(nonce).then( (response) => {
          if (response.result == "OK") {
            this.setState({
              "token": response.data.token,
              "shopperID": response.data.shopperid
            })
          } else {
            this.setState({'alert': {
              type: "danger",
              message: "Error: " + response.message
            }})
          }
        });
      } else {
        this.setState({'alert': {
          type: "danger",
          message: "Error: Reset key not found."
        }})
      }
    }
  }

  render() {
    let alert;
    if (this.state.alert) {
      if (this.state.alert.link && this.state.alert.linkText) {
        alert = <Alert variant={this.state.alert.type}>
            <p>{this.state.alert.message}</p>
            <p><Alert.Link href={this.state.alert.link}>{this.state.alert.linkText}</Alert.Link></p>
          </Alert>
      } else {
        alert = <Alert variant={this.state.alert.type}>{this.state.alert.message}</Alert>
      }
    }
    
    return (
      <StyledForm name="resetPassword" onSubmit={this.handleSubmit} >
        <p className="">Enter your new password.</p>
        {alert}
        <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                
                <Form.Group controlId="formConfirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control 
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    value={this.state.confirmPassword}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
        <div className="float-right">
          <CTAButton variant="solid-white" size="small" type="submit">Reset</CTAButton>
        </div>
      </StyledForm>
    )
  }
}
